// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-blog-js": () => import("./../src/components/blog/blog.js" /* webpackChunkName: "component---src-components-blog-blog-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-ac-dc-emergency-bulb-js": () => import("./../src/pages/products/ac-dc-emergency-bulb.js" /* webpackChunkName: "component---src-pages-products-ac-dc-emergency-bulb-js" */),
  "component---src-pages-products-all-in-one-solar-street-light-js": () => import("./../src/pages/products/all-in-one-solar-street-light.js" /* webpackChunkName: "component---src-pages-products-all-in-one-solar-street-light-js" */),
  "component---src-pages-products-ecco-diva-js": () => import("./../src/pages/products/ecco-diva.js" /* webpackChunkName: "component---src-pages-products-ecco-diva-js" */),
  "component---src-pages-products-high-bay-light-js": () => import("./../src/pages/products/high-bay-light.js" /* webpackChunkName: "component---src-pages-products-high-bay-light-js" */),
  "component---src-pages-products-led-street-light-js": () => import("./../src/pages/products/led-street-light.js" /* webpackChunkName: "component---src-pages-products-led-street-light-js" */),
  "component---src-pages-products-lithium-ion-cell-and-battery-pack-js": () => import("./../src/pages/products/lithium-ion-cell-and-battery-pack.js" /* webpackChunkName: "component---src-pages-products-lithium-ion-cell-and-battery-pack-js" */),
  "component---src-pages-products-solar-fan-js": () => import("./../src/pages/products/solar-fan.js" /* webpackChunkName: "component---src-pages-products-solar-fan-js" */),
  "component---src-pages-products-solar-home-lighting-system-js": () => import("./../src/pages/products/solar-home-lighting-system.js" /* webpackChunkName: "component---src-pages-products-solar-home-lighting-system-js" */)
}

